/* eslint-disable */

/*
 * ⚠️ DO NOT EDIT ⚠️
 * This file is automatically generated, run "yarn run codegen"
 */

import { Types } from '@scorenco/core';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetTournamentsQueryVariables = Types.Exact<{
  now: Types.Scalars['date']['input'];
  addWeek: Types.Scalars['date']['input'];
  subWeek: Types.Scalars['date']['input'];
}>;

export type GetTournamentsQuery = {
  __typename: 'query_root';
  tournaments_tournament?:
    | Array<{
        __typename: 'tournaments_tournament';
        id: number;
        name?: string | undefined;
        start_date?: any | undefined;
        end_date?: any | undefined;
        location?: string | undefined;
        published?: boolean | undefined;
        logo?: string | undefined;
        club?:
          | { __typename: 'clubs_club'; id: number; logo?: string | undefined }
          | undefined;
      }>
    | undefined;
};

export const GetTournamentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTournaments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'now' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'addWeek' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'subWeek' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
          },
        },
      ],
      directives: [
        {
          kind: 'Directive',
          name: { kind: 'Name', value: 'cached' },
          arguments: [
            {
              kind: 'Argument',
              name: { kind: 'Name', value: 'ttl' },
              value: { kind: 'IntValue', value: '300' },
            },
          ],
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tournaments_tournament' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_or' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'start_date' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_lte' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'now' },
                                      },
                                    },
                                  ],
                                },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'end_date' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_gte' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'now' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: '_or' },
                                value: {
                                  kind: 'ListValue',
                                  values: [
                                    {
                                      kind: 'ObjectValue',
                                      fields: [
                                        {
                                          kind: 'ObjectField',
                                          name: {
                                            kind: 'Name',
                                            value: 'start_date',
                                          },
                                          value: {
                                            kind: 'ObjectValue',
                                            fields: [
                                              {
                                                kind: 'ObjectField',
                                                name: {
                                                  kind: 'Name',
                                                  value: '_lte',
                                                },
                                                value: {
                                                  kind: 'Variable',
                                                  name: {
                                                    kind: 'Name',
                                                    value: 'addWeek',
                                                  },
                                                },
                                              },
                                              {
                                                kind: 'ObjectField',
                                                name: {
                                                  kind: 'Name',
                                                  value: '_gte',
                                                },
                                                value: {
                                                  kind: 'Variable',
                                                  name: {
                                                    kind: 'Name',
                                                    value: 'subWeek',
                                                  },
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                    {
                                      kind: 'ObjectValue',
                                      fields: [
                                        {
                                          kind: 'ObjectField',
                                          name: {
                                            kind: 'Name',
                                            value: 'end_date',
                                          },
                                          value: {
                                            kind: 'ObjectValue',
                                            fields: [
                                              {
                                                kind: 'ObjectField',
                                                name: {
                                                  kind: 'Name',
                                                  value: '_lte',
                                                },
                                                value: {
                                                  kind: 'Variable',
                                                  name: {
                                                    kind: 'Name',
                                                    value: 'addWeek',
                                                  },
                                                },
                                              },
                                              {
                                                kind: 'ObjectField',
                                                name: {
                                                  kind: 'Name',
                                                  value: '_gte',
                                                },
                                                value: {
                                                  kind: 'Variable',
                                                  name: {
                                                    kind: 'Name',
                                                    value: 'subWeek',
                                                  },
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'start_date' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start_date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'end_date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                { kind: 'Field', name: { kind: 'Name', value: 'published' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'club' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetTournamentsQuery, GetTournamentsQueryVariables>;
