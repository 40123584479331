import { useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import { Android, Apple } from '@mui/icons-material';
import {
  Typography,
  Stack,
  AppBar,
  Toolbar,
  Box,
  Button,
  Container,
  Link,
  Divider,
} from '@mui/material';
import { AppBarLogo, useItems, Image } from '@scorenco/components';
import { BaseLayout, NextPageWithLayout } from '@scorenco/next';
import { addDays, subDays, startOfToday, format } from 'date-fns';
import Head from 'next/head';
import NextLink from 'next/link';
import { useRouter } from 'next/router';

import { GetTournamentsDocument } from './_internal/index.query.generated';

const Home: NextPageWithLayout = () => {
  const router = useRouter();

  const now = startOfToday();

  const getTournamentsQuery = useQuery(GetTournamentsDocument, {
    variables: {
      now: now.toISOString(),
      addWeek: addDays(now, 4).toISOString(),
      subWeek: subDays(now, 10).toISOString(),
    },
    context: {
      role: 'anonymous',
    },
  });

  const tournaments = useItems(
    getTournamentsQuery.data?.tournaments_tournament,
    3
  );

  return (
    <>
      <Head>
        <title key="title">Tournois - Score'n'co</title>
      </Head>

      <AppBar>
        <Toolbar>
          <Box component="a" onClick={() => router.push(`/`)}>
            <AppBarLogo />
          </Box>

          <Box display={{ xs: 'none', md: 'block' }}>
            <NextLink legacyBehavior href="https://scorenco.com" passHref>
              <Button variant="contained" color="secondary">
                Découvrir Score'n'co
              </Button>
            </NextLink>
          </Box>
        </Toolbar>
      </AppBar>

      <Box
        component="main"
        sx={{
          paddingTop: '4rem',
          maxWidth: '100%',
        }}
      >
        <Container>
          <Stack spacing={4} sx={{ mt: 3, pb: 3, alignItems: 'center' }}>
            {!tournaments.length ? (
              <>
                <Typography
                  variant="body1"
                  sx={{ textAlign: 'center', maxWidth: '25rem' }}
                >
                  Il n'y a aucun tournoi en cours.
                  <br />
                  Reviens plus tard 😉
                </Typography>
                <Divider sx={{ width: '100%' }} />
              </>
            ) : (
              <Stack sx={{ width: '100%' }} spacing={2}>
                <Typography variant="h3">Tournoi(s) en cours</Typography>
                <Stack spacing={1}>
                  {tournaments.map((tournament, i) => (
                    <Button
                      key={i}
                      css={(theme) => css`
                        flex: 1;
                        width: 100%;
                        text-transform: none;
                        color: ${theme.vars.palette.primary.main};
                        background: white;
                        border-radius: 0.25rem;
                        border: 1px solid ${theme.vars.palette.divider};

                        &.mui-selected: {
                          border-color: ${theme.vars.palette.primary.main};
                        }

                        padding-left: 2rem;
                        padding-right: 2rem;
                        padding-top: 1rem;
                        padding-bottom: 1rem;
                      `}
                      onClick={() => {
                        router.push(`/${tournament?.id}`);
                      }}
                    >
                      <Stack direction="row" gap={2} sx={{ width: '100%' }}>
                        {(tournament?.logo || tournament?.club?.logo) && (
                          <Image
                            src={tournament.logo || tournament.club.logo}
                            css={css`
                              width: 100%;
                              max-width: 3rem;
                              height: auto;
                              object-fit: contain;
                            `}
                          />
                        )}
                        <Stack spacing={1} sx={{ width: '100%' }}>
                          <Typography
                            variant="h4"
                            component="h4"
                            sx={{ lineHeight: 1.2, fontSize: '1.1rem' }}
                          >
                            {tournament?.name}
                          </Typography>
                          <Stack
                            spacing={4}
                            direction="row"
                            justifyContent="center"
                          >
                            <Typography
                              variant="body2"
                              component="p"
                              sx={{ m: 0 }}
                            >
                              {tournament?.location}
                            </Typography>
                            <Typography
                              variant="body2"
                              component="p"
                              sx={{ m: 0 }}
                            >
                              {tournament?.start_date &&
                                format(
                                  new Date(tournament.start_date),
                                  'dd/MM/yyyy'
                                )}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Button>
                  ))}
                </Stack>
              </Stack>
            )}
            <Stack sx={{ width: '100%' }} spacing={2}>
              <Typography variant="h3">
                Télécharger l'application Score'n'co 📲
              </Typography>

              <Stack gap={1}>
                <Link
                  href="https://apps.apple.com/fr/app/scorenco-sport-en-direct/id816395945"
                  color="inherit"
                  underline="hover"
                  target="_blank"
                  rel="noopener"
                >
                  <Button
                    css={(theme) => css`
                      flex: 1;
                      width: 100%;
                      text-transform: none;
                      color: ${theme.vars.palette.primary.main};
                      background: white;
                      border-radius: 0.25rem;
                      border: 1px solid ${theme.vars.palette.divider};

                      &.mui-selected: {
                        border-color: ${theme.vars.palette.primary.main};
                      }

                      padding-left: 2rem;
                      padding-right: 2rem;
                      padding-top: 1rem;
                      padding-bottom: 1rem;
                    `}
                  >
                    <Apple sx={{ mr: 0.5 }} /> Application IOS
                  </Button>
                </Link>
                <Link
                  href="https://play.google.com/store/apps/details?id=com.scorenco.app"
                  color="inherit"
                  underline="hover"
                  target="_blank"
                  rel="noopener"
                >
                  <Button
                    css={(theme) => css`
                      flex: 1;
                      width: 100%;
                      text-transform: none;
                      color: ${theme.vars.palette.primary.main};
                      background: white;
                      border-radius: 0.25rem;
                      border: 1px solid ${theme.vars.palette.divider};

                      &.mui-selected: {
                        border-color: ${theme.vars.palette.primary.main};
                      }

                      padding-left: 2rem;
                      padding-right: 2rem;
                      padding-top: 1rem;
                      padding-bottom: 1rem;
                    `}
                  >
                    <Android sx={{ mr: 0.5 }} /> Application Android
                  </Button>
                </Link>
              </Stack>
            </Stack>

            <Stack sx={{ width: '100%' }} spacing={1} alignItems="center">
              <Typography
                variant="h6"
                sx={{ textAlign: 'center', maxWidth: '25rem' }}
              >
                Votre club est organisateur de tournois ?<br />
                Contactez-nous pour tester notre solution automatisée de
                communication & gestion de tournois 🏆
              </Typography>
              <Typography
                variant="h5"
                sx={{ textAlign: 'center', maxWidth: '25rem' }}
              >
                <Link
                  href="mailto:contact@scorenco.com"
                  color="inherit"
                  underline="hover"
                  target="_blank"
                  rel="noopener"
                >
                  contact@scorenco.com
                </Link>
              </Typography>
            </Stack>
          </Stack>
        </Container>
      </Box>
    </>
  );
};

export const getStaticProps = BaseLayout.buildGetStaticProps();

Home.getLayout = BaseLayout.buildGetLayout();

export default Home;
